
@fsize: @font-size-base;
@line-height: @fsize+1px;
@abstract-line-num: 3;
@line-num: 3+@abstract-line-num;
@margin: 10px;
@padding: 15px;
@padding-top: @padding - 10px;

.comment-item {
  //padding-left: 15px;
  //padding-right: 15px;
  .comment-item-inner {
    //overflow-y: hidden;
    margin-bottom: @margin;
    margin-top: @margin;
    padding: @padding-top @padding 0;
    background-color: rgb(255, 255, 255);
    font-size: @fsize;
    line-height: @line-height;
    border-radius: 4px;
    border: rgba(0, 0, 0, 0.12) 1px solid;

    .research-item{
      padding: 0;

      .research-item-inner{
        margin-top:0;
      }
    }

  }
}
