.login-container {
  top: 0px;
  height: 100%;

  &-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;

    .login-img {
      margin: auto;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &-toolbar-title {
    background-color: white;
    color: black;
    border-bottom: solid 1px gray;

    img {
      width: 50px;
      padding-top: 5px;
      margin-right: 15px;
    }
  }

  .loading {
    padding-bottom: 20px;
  }
}

.login-container::after {
  content: "";
  background-image: url(@img-header);
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.delimiter-or {
  background-color: white;
  text-align: center;
  font-variant: all-small-caps;
  margin-left: 20px;
  margin-right: 20px;

  hr {
    margin-bottom: 0;
    position: relative;
    top: 21px;
    height: 0;
    border: 0;
    border-top: 1px solid #e4e6e8;
  }

  span {
    display: inline-block;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #FFF;
  }
}

.signin-3rd-party {
  button {
    border: solid 1px darkblue;
    margin: 0 auto;
    margin-bottom: 5px;
    width: 250px;
  }
}