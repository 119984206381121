@chatbox-width: 300px;
@chatbox-height: 350px;
@chatbox-border-color: #000000;

.chatbox-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;

  .chatbox-outer {
    position: relative;
    height: @chatbox-height;
    width: @chatbox-width;
    float: right;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 3px;
  }

  .chatbox {
    width: @chatbox-width;
    border: solid 1px @chatbox-border-color;
    font-size: 16px;
    font-family: "Roboto, Helvetica Neue, sans-serif";
    height: @chatbox-height;
    max-height: @chatbox-height;

    &.minimized {
      height: auto;
      bottom: 0;
      position: absolute;
    }

    .content {
    }
  }
}

.i8x-chat {

  .chat-status-bar {
    flex-shrink: 0;
    height: 15px;
  }
}