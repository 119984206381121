.infinite-list-outer{
  display: block;
  position: relative;

  .il-counter{
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.4);
    font-size: @font-size-small;
    padding-right: 5px;
  }

  .infinite-list-inner{
    display: block;
    overflow-x: hidden;
    overflow-y: auto;

    //-webkit-mask-image: -webkit-gradient(linear, left top,
    //left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(5%,rgba(255,255,255,1)),
    //color-stop(85%,rgba(255,255,255,1)),color-stop(100%,rgba(255,255,255,0)));

    .infinite-scroll {
      margin-top: 50px;
      position: relative;
      //margin-bottom: 50px;
      .infinite-scroll-item-index{
        position: absolute;
        right: 16px;
        top: 2px;
        font-size: 9px;
        line-height: 9px;
        color: @my;
      }
    }

  }

  .options-outer {
    position: absolute;
    top:0;
    left: 0;
    right: 15px;
    background-color: @my-background;
    &:before {
      box-shadow: 0 0 4px rgba(255,255,255,.14), 0 4px 8px rgba(255,255,255,.28);
    }



    .options {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: .005em;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap; /* Safari 6.1+ */
      flex-wrap: wrap;
      padding: 0 0 4px 4px;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      justify-content: flex-end;

      & > div md-select {
        margin: 0 4px;
      }

      .export-btn{
        width: 30px;
        height: 30px;
        min-height: 30px;
        padding: 3px;
        padding-bottom: 0;
      }
    }
  }
}