@toolbar-height: 48px;

.content {
  .content-toolbar {

    min-height: @toolbar-height;
    height: @toolbar-height;
    max-height: @toolbar-height;

    @media (min-width: 960px) {
      position: fixed;
      top: 0;
    }

    .md-toolbar-tools {
      width: 100%;
      height: 100%;

      button.logo-btn {
        width: 62px;
        min-width: 62px;
        /* padding-top: 11px; */
        height: 32px;
        min-height: 32px;
        padding: 4px 6px;

        img.logo {
          width: 50px;
          height: 24px;
        }
      }

    }
  }

  .content-div {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    min-height: calc(~"100vh - @{toolbar-height}");

    @media (min-width: 960px) {
      margin-top: @toolbar-height;
      height: calc(~"100vh - @{toolbar-height}");
    }
    .content-div-inner {

      max-width: @app-width;
      margin-right: auto;
      margin-left: auto;
      width: 100%;

      .content-data{
        max-width: 1080px;
        width: 100%;
      }
    }
  }

  .content-chat{
    position: fixed;
    background-color: #5e5e5e;
    width: 200px;
    top: 48px;
    right: 0;
    bottom: 0;
  }
}

.content-full-height {
  height: calc(~"100vh - 48px");
}

.content-full-screen {
  height: calc(~"100vh - 48px");
  width: 100vw;
  position: absolute;
  left: 0;
}



md-sidenav.md-sidenav-notification,
md-sidenav.md-sidenav-notification.md-locked-open-add-active,
md-sidenav.md-sidenav-notification.md-closed.md-locked-open-add-active,
md-sidenav.md-sidenav-notification.md-locked-open {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
}

.search-container{
  margin: 0 22px;
  background-color: rgb(158,158,158);
  height: 30px;
  position: relative;

  input{
    background-color: transparent;
    border-color: transparent;
    font-size: @font-size-base;
    line-height: @font-size-base;
    padding: 0;
    padding-left: 5px;
    padding-right: 30px;
    border: none;
    height: 100%;
    color: rgba(0, 0, 0, 0.7);

  }

  .md-button{
    position: absolute;
    right: 0;
    margin: 0;
    padding: 0;
    width: 30px;
    min-width: 30px;
    max-width: 30px;
    height: 100%;
    min-height: 100%;
    max-height: 100%;

    md-icon {
      margin: 0px;
      margin-bottom: 8px;
    }
  }

}
