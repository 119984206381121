@chatbox-input-height: 40px;

.chatbox-input {
  flex-shrink: 0;
  height: @chatbox-input-height;

  .input {
    font-size: 16px;
    font-family: "Roboto, Helvetica Neue, sans-serif";
    line-height: @chatbox-input-height;
  }
}