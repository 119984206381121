.timeline {
  overflow-y: auto;
  .timeline-inner{
    .timeline-list{


      @fsize: @font-size-base;
      @line-height: @fsize+1px;
      @abstract-line-num: 3;
      @line-num: 3+@abstract-line-num;
      @margin: 10px;
      @padding: 15px;

      .timeline-item {
        padding-left: 5px;
        padding-right: 15px;
        .timeline-item-inner {
          //overflow-y: hidden;
          margin-bottom: @margin;
          margin-top: @margin;
          background-color: rgb(255, 255, 255);
          font-size: @fsize;
          line-height: @line-height;
          border-radius: 4px;
          padding: @padding;
          border: rgba(0, 0, 0, 0.12) 1px solid;

          .research-item{
            padding: 0;
          }

          .timeline-data{

            .timeline-time{
              color: @my;
              font-style: italic;
              font-size: @fsize - 1px;
            }
          }

        }
      }

    }


  }
}