.profile{
  padding: 15px;
  .item {
    font-size: @font-size-base;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: @font-size-base+1;
  }

  .md-title{
    margin-bottom: 0;
    margin-top: 0;
  }

  md-list{
    padding: 0;

    md-list-item{
      padding-right: 0;
    }
  }
}