.tweets {
  @fsize: @font-size-base;
  @line-height: @fsize+4px;
  @twitter-text-line-num: 3;
  @twitter-line-num: 5;

  .tweet-item {
    .tweet-item-inner {
      //height: @twitter-line-num*@line-height + 5px;
      .text {
        font-size: @font-size-base;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        max-height: @twitter-text-line-num*@line-height; /* fallback */
        -webkit-line-clamp: @twitter-text-line-num; /* number of lines to show */
        -webkit-box-orient: vertical;
      }
      .time {
        font-size: @font-size-small;
        margin-top: 3px;
        font-style: italic;
      }
    }
  }
}