html, body {
  min-height: 100%;
  overflow: auto;
  color: #222222;
  background-color: @my-background;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-c-a {
  flex: 1 1 auto;
}

.flex-c-g {
  flex: 1 0 auto;
}

.flex-c-n {
  flex: none;
}

.flex-c-40 {
  .flex-c-n;
  width: 40%;
}

.flex-c-50 {
  .flex-c-n;
  width: 50%;
}

.flex-c-25 {
  .flex-c-n;
  width: 25%;
}

.flex-c-20 {
  .flex-c-n;
  width: 20%;
}

.flex-c-33 {
  .flex-c-n;
  width: 33.3%;
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.vertical-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}