.messages-panel {
  .content-full-screen;

  .messages-roster {
    border-right: solid 1px black;
    height: 100%;

    .roster-item {
      width: 250px;

      span {
        line-height: normal; // to override md-button line height
      }

      .avatar {
        margin-right: 8px;
      }

      .last-message {
        width: 190px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 12px;
        color: gray;
      }
    }
  }

  .i8x-chat {
    justify-content: center;

    .chat-bubble {
      max-width: 300px;
    }

    .content {
      max-width: 700px;
    }
  }
}