.md-sidenav-search-filters{


  &.md-locked-open{
    background-color: @my-background;

    md-content{
      background-color: @my-background;
    }
  }


}