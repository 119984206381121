a:not(.md-button) {
  color: @brand-primary;
  text-decoration: none;
}

a:visited:not(.md-button), a:hover:not(.md-button) {
  color: @link-hover-color
}

.loading-lg {
  .fullscreen;
  min-height: 50px;
  & > span {
    .vertical-center;
  }
  font-size: @font-size-large;
  font-weight: bold;
  line-height: 50px;
  .fa {
    margin-right: 3px;
  }
  img {
    height: 40px;
  }
}

.loading-sm {
  .fullscreen;
  min-height: 50px;
  & > span {
    .vertical-center;
  }
  font-size: @font-size-base;
  font-weight: bold;
  line-height: 30px;
  img {
    height: 35px;
  }
}

.loading-inline {
  font-size: @font-size-base;
  font-weight: bold;
  line-height: 30px;
  img {
    height: 35px;
  }
}

.loading-inline-center{
  .loading-inline;

  text-align: center;
}

.red {
  background-color: red;
}

.blue {
  background-color: blue;
}

.green {
  background-color: green;
}

.red-text {
  color: red;
}

.blue-text {
  color: blue;
}

.green-text {
  color: green;
}

md-toast {
  &.md-top {
    top: 45px;
  }
  &.info {
    .md-toast-content {
      background-color: @brand-info;
    }
  }

  &.success {
    .md-toast-content {
      background-color: @brand-success;
    }
  }

  &.error {
    .md-toast-content {
      background-color: @brand-danger;
    }
  }

  &.warning {
    .md-toast-content {
      background-color: @brand-warning;
    }
  }

  @media (max-width: 959px){
    //top: 40px;
    bottom: 54px;
  }

}

.headroom {
  will-change: transform;
  transition: transform 300ms linear;
}

.headroom--pinned {
  transform: translateY(0%);
}

.headroom--unpinned {
  transform: translateY(-100%);
}

md-menu-item {
  .no-padding {
    padding-left: 0;
    padding-right: 0;
  }

  md-menu {

    .md-button {
      text-align: left;
      display: inline-block;
      border-radius: 0;
      margin: auto 0;
      font-size: 15px;
      text-transform: none;
      font-weight: 400;
      min-height: 48px;
      height: 48px;
      padding-left: 16px;
      padding-right: 16px;
      width: 100%;

      md-icon {
        margin: auto 16px auto 0;
      }
    }
  }
}

.md-button.md-icon-button.counter-button{
  position: relative;
  .counter{
    position: absolute;
    top: 5px;
    color: white;
    font-size: 10px;
    background-color: @brand-primary;
    height: 12px;
    right: 5px;
    width: 12px;
    border-radius: 6px;
    line-height: 11px;
  }
}


.hide-validation-error, .md-chip-input-container{
  .md-errors-spacer{
    display: none;
  }
}

.margin-t8{
  margin-top: 8px;
}

button.user-avatar-main{
  @user-avatar-main-size: 40px;
  margin: 0px;
  padding: 0px;

  img.md-avatar{
    height: @user-avatar-main-size;
    width: @user-avatar-main-size;
    margin: 0 !important;
    margin-left: -6px;
  }
}

.user-avatar-mini{
  @user-avatar-mini-size: 32px;
  margin: 0px;
  padding: 0px;
  display: inline-block;
  width: @user-avatar-mini-size;
  height: @user-avatar-mini-size;

  .md-avatar{
    height: @user-avatar-mini-size !important;
    width: @user-avatar-mini-size !important;
    font-size: @user-avatar-mini-size;
    line-height: normal;
    vertical-align: baseline;
    margin: 0 !important;
    padding: 0;
    flex: none;
    box-sizing: content-box;
    border-radius: 50%;
  }

  .avatar-item {
    display: inline-block;
    position: relative;
  }

  &.status-online .md-avatar::after {
      content: '';
      display: inline-block;
      position: relative;
      top: -2px;
      background-color: @status-online-color;
      height: 10px;
      right: 11px;
      width: 10px;
      border-radius: 5px;
      border: solid 1px rgba(255, 255, 255, .7);
  }

  &.status-online .avatar-item .badge::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 5px;
    background-color: @status-online-color;
    height: 10px;
    right: -4px;
    width: 10px;
    border-radius: 5px;
    border: solid 1px rgba(255, 255, 255, .7);
  }
}

.user-avatar-max{
  @user-avatar-max-size: 96px;
  margin: 0px;
  padding: 0px;
  display: inline-block;
  width: @user-avatar-max-size;
  height: @user-avatar-max-size;

  .md-avatar{
    height: @user-avatar-max-size !important;
    width: @user-avatar-max-size !important;
    font-size: @user-avatar-max-size;
    line-height: normal;
    vertical-align: baseline;
    margin: 0 !important;
    padding: 0;
    flex: none;
    box-sizing: content-box;
    border-radius: 50%;
  }

  .avatar-item {
    display: inline-block;
    position: relative;
  }
}
