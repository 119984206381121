@chatbox-bubble-color: #00aabb;
@chatbox-bubble-color-secondary: #AAAAAA;

.chatbox-content {
  .chat-row {
    padding: 0;
    padding-bottom: 5px;
    word-wrap: break-word;

    .text {
      padding: 5px;
      color: white;
    }
  }

  .date-info {
    text-align: center;
    font-size: 14px;
    color: gray;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .avatar-info {
    width: 52px;
  }

  .text-info {
    font-size: 12px;
    float: right;
  }

  .chat-bubble {
    position: relative;
    background: @chatbox-bubble-color;
    border-radius: .4em;

    &:after {
      content: '';
      position: absolute;
      top: 20px;
      width: 0;
      height: 0;
      border: 8px solid transparent;
      margin-top: -8px;
    }

    &.left:after {
      left: 0;
      border-right-color: @chatbox-bubble-color;
      border-left: 0;
      margin-left: -8px;
    }

    &.right:after {
      right: 0;
      border-left-color: @chatbox-bubble-color;
      border-right: 0;
      margin-right: -8px;
    }

    &.history {
      background: @chatbox-bubble-color-secondary;
      border-left-color: @chatbox-bubble-color-secondary;
      border-right-color: @chatbox-bubble-color-secondary;
    }

    &.history:after {
      border-left-color: @chatbox-bubble-color-secondary;
      border-right-color: @chatbox-bubble-color-secondary;
    }
  }
}