
@fsize: @font-size-base;
@line-height: @fsize+1px;
@abstract-line-num: 3;
@line-num: 3+@abstract-line-num;
@margin: 10px;
@padding: 15px;

.research-item {
  padding-left: 5px;
  padding-right: 15px;
  .research-item-inner {
    //height: @line-num*@line-height+2*(@padding);
    //overflow-y: hidden;
    margin-bottom: @margin;
    margin-top: @margin;
    padding: @padding;
    padding-bottom: 0;
    background-color: rgb(255, 255, 255);
    font-size: @fsize;
    line-height: @line-height;
    border-radius: 4px;
    border: rgba(0, 0, 0, 0.12) 1px solid;
    position: relative;

    .title {
      height: @line-height;
      margin-top: 0px;
      margin-bottom: 4px;
      margin-right: 30px;
      font-weight: 500;
      a {

        max-width: 100%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .author-line {
      margin-top: 0px;
      margin-bottom: 4px;
      color: @my;
      font-style: italic;
      font-size: @fsize - 1px;

      .author-line-nogrow {
        max-width: 100%;
        min-width: 50px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .abstract {
      font-size: @font-size-small;
      line-height: @line-height;
      overflow: hidden;
      white-space: pre-wrap;
      //text-overflow: ellipsis;
      //display: -webkit-box;
      //max-height: @abstract-line-num*@line-height; /* fallback */
      //-webkit-line-clamp: @abstract-line-num; /* number of lines to show */
      //-webkit-box-orient: vertical;
    }
    /*                    .link {
                            margin-bottom: 2px;
                            max-width: 100%;
                            display: inline-block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        } */

    .actions{
      margin-left: -16px;
      margin-right: -16px;

      a{
        margin-right: 10px;
      }

    }

    .more{
      position: absolute;
      right: -6px;
      top: 0;
    }

  }
}

.comments{

  .add-comment, .all-comments{
    position: relative;

    md-list {
      padding: 0;

      md-list-item {
        padding: 4px 0;
        font-size: @font-size-base;

        .comment-user {
          width: 100px;
          text-align: right;
          padding-right: 10px;
        }

        md-input-container {
          margin: 0;

          textarea {
            border-width: 2px;
            border-radius: 4px;
            line-height: @line-height;
            padding: 4px;
          }

          .md-errors-spacer {
            display: none;
          }
        }
      }
    }
  }
}

.research-item-details, .research-item-reviews, .research-item-claim{
  .details{
    &>div{
      margin-bottom: 4px;
      margin-top: 4px;
    }
  }

  .reviews{

    .review-item{

    }
  }

  md-chips.bc-address-chips input{
    width: 400px;
  }
}